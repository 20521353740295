<template>
  <img :src="downImg" alt="logo" width="200"/>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  data() {
    return{
      downImg : require('@/assets/images/logo/stocks_easy_logo.png')
    }
  },
})
</script>

